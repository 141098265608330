import CONTEXT_REDUCER_MAPPING_KEY from 'common/constants/ContextReducerKeys';
import { MENU_PDP_PAGE_CONSTANTS } from '../organisms/MenuPDPPage/AU/Constants/MenuPDPPageConstants';
import { CART_PAGE_CONSTANTS } from '../organisms/CartPage/Constants/CartPageConstants';
import {
  SHOW_COPY_COUPON_ACTION,
  HIDE_COPY_COUPON_ACTION,
} from 'organisms/DealsAndOffersLanding/constants/offersAndDealsConstants';
import {
  SET_COUPON_ERROR_MESSAGE,
  SET_COUPON_EXPIRY_MESSAGE,
} from 'organisms/CouponsLanding/Constants/CouponConstants';
import DISPOSITION_CONSTANTS from 'organisms/DispositionModule/Constants/DispositionConstants';
import HEADER_CONSTANT from 'organisms/HeaderComponent/AU/HeaderConstants/HeaderConstant';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';
import { BRAZE_ACCOUNT_MSG } from 'common/constants/SharedConstants';
import { AUTO_DETECT_CONSTANTS } from 'organisms/SearchStore/Constants/AutoDetectionConstants';

export const orderStateReducer = (
  state = {
    dispositionStateData: {},
    searchStoreData: {},
    scheduleOrderData: {},
    selectedTile: {},
    confirmUpsizeName: 'none',
    dispositionOld: {
      type: '',
      store: '',
      date: '',
      time: '',
      interval: '',
      deliveryAddress: '',
      deliveryAddressLine: '',
    },
    dispositionNew: {
      type: '',
      store: '',
      date: '',
      time: '',
      interval: '',
      deliveryAddress: '',
      deliveryAddressLine: '',
      selectedTime: '',
    },
    pdpScreenCommons: {
      expandType: 'all',
      currentAccordian: '',
      modGroupIdsUpdated: [],
    },
    cartAlertData: {
      showCartAlert: false,
    },
    cartItems: {
      currentEditItem: {},
      itemLocations: [],
    },
    cartStateData: {
      showCartState: false,
    },
    deliveryAddress: '',
    showLanguageSelector: true,
    addToCart: false,
    productItemId: '',
    categoryId: '',
    oldProduct: {},
    isLocalized: false,
    customizable: false,
    catalogs: '',
    fromPdpPage: false,
    ProductDetails: {},
    showCouponCopied: false,
    showCartClearAlert: false,
    modalToOpenOnConfirm: '',
    modalOpenFrom: '',
    confirmCallBack: null,
    currentStoreInfo: null,
    minipdpData: {
      itemId: '',
      fromFlag: '',
      foodLineId: '',
    },
    cartEditClicked: false,
    deeplinkSelectedStoreId: '',
    deepLinkStoreMode: false,
    showCartErrorModal: false,
    forceReload: false,
    autoDetectionClicked: false,
    showLocationBlockBanner: false,
    pdpDeepLinking: false,
    isFromMultiProductViewModal: false,
    sopacPDPCustomization: {
      showPDPCustomization: false,
      showCustomizationModId: '',
      showCustomizationModGrpId: '',
      showCustomizationModGrpName: '',
      updatedPrice: 0,
    },
    pdpItemCustomType: false,
    pdpItemUpsizeId: '',
    hideDispositionBar: false,
    hideAccountPageHeaders: false,
    pdpItemL3Data: [],
    pdpPayloadData: {},
    showToastAlert: false,
    resetCustomization: false,
    showLocationConfirmModal: false,
    setLocationClicked: false,
    removeItemCart: false,
    unitNumber: '',
    pdpDataForUpdateFoodLine: {
      itemId: '',
      flag: '',
      foodLineId: '',
    },
    startOrderBtnClicked: false,
    orderModeBrowseMenuBtnClicked: false,
    deliveryAddressLine: '',
    isPdpPageActive: false,
    eligibleStore: [],
    showNotInRadiusError: false,
    notInRadiusRestaurant: null,
    pdpAddtoCartConfirmButtonDisabled: {},
    isFromRecommendedSectionItemClick: false,
    alertModalData: {
      flag: false,
      pathname: '',
      title: '',
      contentMsg: '',
    },
    findAKFC: false,
    enableASAPScheduling: false,
    showOrderNow: false,
    showScheduleOrder: true,
    showOrderNowReorder: false,
    showScheduleOrderReorder: false,
    isFutureTimeDateOrder: false,
    redirectionOrder: '',
    pdpPromoPage: false,
    isDeepLinkURL: false,
    menuNavRestricted: {
      flag: '',
      pathnameToNavigate: '',
    },
    timeBasedAlertModalData: {},
    timeBasedCetegory: {},
    scheduleModalOpen: false,
    placeOrderButtonEnabled: true,
    orderSummaryModalClose: true,
    changeScheduleModeFlag: false,
    scheduleModeClicked: false,
    showSheduleDisposition: false,
    selectedDeliveryAddress:'',
  },
  action = {},
) => {
  const {
    INITIAL_DISPOSITION_DATA,
    DISPOSITION_DATA,
    SEARCH_ORDER_DATA,
    SCHEDULE_ORDER_DATA,
    DISPOSITION_METHOD,
    SEARCH_ORDER_USER_ADDRESS,
    SCHEDULE_ORDER_TIME_DATA,
    SCHEDULE_ORDER_TIME_PRESERVE_DATE,
    SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    SELECTED_TILE_DATA,
    SET_DISPOSITION,
    SET_DISPOSITION_NEW,
    CART_STATES_DATA,
    CONFIRM_UPSIZE_ANALYTICS,
    ADD_TO_CART_CLICK,
    USER_LOCALIZED,
    LANGUAGE_SELECTOR,
    PLP_PDP_DETAILS,
    SHOW_CLEAR_CART_ALERT,
    SET_LANGUAGE,
    CLEAR_CART_CONFIRM_CANCEL_MODAL,
    CURRENT_STORE_INFO,
    MINI_PDP_DATA,
    EDIT_CLICKED,
    TENDER_DATA,
    PAYMENET_FORM_PAYLOAD,
    SHOW_CART_ERROR_MODAL,
    SET_FORCERELOAD,
    SHOW_LOCATION_CONFIRM_MODAL,
    START_ORDER_CLICK,
    ORDER_MODE_BROWSE_MENU_CLICK,
    RESET_DELIVERY_ADDRESS_LINE,
    SET_ELIGIBLE_STORE,
    FAV_STORE_NOT_IN_RADIUS,
    UPDATE_CATERING_RETAIL_MENU_ALERT,
    SET_FIND_A_KFC_VIEW,
    SET_ASAP_SCHEDULING,
    SET_ORDER_NOW,
    SET_SCHEDULE_ORDER,
    SET_REDIRECTION_PATH,
    IS_DEEPLINK_URL,
    REORDER_MODAL_OPEN,
    FROM_REORDER_MODAL_OPEN,
    REORDER_STORE_CHANGED,
    MENU_NAV_ERROR,
    UPDATE_TIMEBASED_MENU_ALERT,
    UPDATE_TIMEBASED_MENU_DATA,
    SCHEDULE_MODAL_OPEN,
    PLACE_ORDER_BUTTON,
    ORDER_SUMMARY_MODAL_CLOSE,
    SET_ORDER_NOW_REORDER,
    SET_SCHEDULE_ORDER_REORDER,
    CHANGE_SCHEDULE_MODE,
    SCHEDULE_ORDER_CLICKED,
    SHOW_SCHEDULE_DISPOSITION,
    SET_LOCATION_INFO,
  } = CONTEXT_REDUCER_MAPPING_KEY;
  const {
    CUSTM_EXPAND_TYPE,
    EXPAND_ACCORDIAN,
    MODGROUP_IDS_UPDATE,
    UPDATE_DEFAULT_MODGROUP,
    RESET_MODGROUP_IDS_UPDATE,
    SET_PDP_DEEPLINK_MODE,
    SET_MULTI_PRODUCT_VIEW_MODAL,
    SET_PDP_PROMO_MODE,
    PDP_ITEM_CUSTOM_TYPE,
    PDP_ITEM_UPSIZE_ID,
    PDP_L3_CUSTOMIZED_DATA,
    PDP_PAYLOAD_DATA,
    SHOW_PDP_CUSTOM_MODAL,
    UPDATE_SHOW_PDP_CUSTOM_MODAL,
    RESET_SHOW_PDP_CUSTOM_MODAL,
    SHOWTOASTALERT,
    RESET_CUSTOMIZATION,
    PDP_DATA_FOR_UPDATE_FOODLINE,
    SET_PDP_PAGE_STATUS,
    SET_PDP_BUTTON_DISABLED,
    RECOMMENDED_SECTION_ITEM_CLICK,
  } = MENU_PDP_PAGE_CONSTANTS;
  const { CART_ALERT, SET_ADDED_LOCATION, REMOVE_ITEM_FLAG } = CART_PAGE_CONSTANTS;
  const {
    DISPOSITION_ACTION: { SET_QR_DEEPLINK_MODE },
  } = DISPOSITION_CONSTANTS;
  const { AUTO_DETECTION_BUTTON_FLAG, LOCATION_BLOCK_BANNER } = AUTO_DETECT_CONSTANTS;
  switch (action.type) {
    case INITIAL_DISPOSITION_DATA:
      const data = {
        ...state.dispositionStateData,
        contentData: { ...action.value },
      };
      return { ...state, dispositionStateData: data };
    case DISPOSITION_DATA:
      const updatedDispositionData = {
        ...state.dispositionStateData,
        showDisposition: action.value.showDisposition,
      };
      return { ...state, dispositionStateData: updatedDispositionData };
    case DISPOSITION_METHOD:
      return {
        ...state,
        dispositionStateData: {
          ...state.dispositionStateData,
          dispositionType: action.value.dispositionType,
          // contentData: action.value.changeDispositionData,
        },
      };
    case SEARCH_ORDER_DATA:
      const updatedSearchStoreData = {
        ...state.searchStoreData,
        showSearchStore: action.value.showSearchStore,
        searchedPlaceNonLocalized: action.value.searchedPlaceNonLocalized,
        configSearchScreen: action?.value?.configSearchScreen || state?.searchStoreData?.configSearchScreen,
      };
      return { ...state, searchStoreData: updatedSearchStoreData };
    case SEARCH_ORDER_USER_ADDRESS:
      return {
        ...state,
        searchStoreData: {
          ...state.searchStoreData,
          userAddress: action.value.userAddress,
        },
      };
    case SCHEDULE_ORDER_DATA:
      const updatedScheduleOrderData = {
        ...state.scheduleOrderData,
        showScheduleOrderCatering: action.value.showScheduleOrderCatering,
        storeInfo: action.value.selectedStoreInfo,
        changeFlow: action.value.changeFlow,
        isAddressFlow: action.value.isAddressFlow,
        deliveryAddress: action.value.deliveryAddress
          ? action.value.deliveryAddress
          : state.scheduleOrderData.deliveryAddress,
        deliveryAddressLine: action?.value?.deliveryAddressLine || state?.scheduleOrderData?.deliveryAddressLine,
      };
      return { ...state, scheduleOrderData: updatedScheduleOrderData };
    case SELECTED_TILE_DATA:
      return { ...state, selectedTile: action.value.selectedData };
    case SCHEDULE_ORDER_TIME_DATA:
      return {
        ...state,
        scheduleOrderData: {
          ...state.scheduleOrderData,
          selectedDateTimeInfo: action.value.selectedDateTime,
        },
      };
    case SCHEDULE_ORDER_TIME_PRESERVE_DATE:
      return {
        ...state,
        scheduleOrderData: {
          ...state.scheduleOrderData,
          selectContentData: {
            ...state.scheduleOrderData.selectContentData,
            savedDate: action.value.selectContentDate,
            //savedTime : action.value.selectContentTime,
          },
        },
      };
    case SCHEDULE_ORDER_TIME_PRESERVE_TIME:
      return {
        ...state,
        scheduleOrderData: {
          ...state.scheduleOrderData,
          selectContentData: {
            ...state.scheduleOrderData.selectContentData,
            //savedDate : action.value.selectContentDate,
            savedTime: action.value.selectContentTime,
          },
        },
      };
    case CUSTM_EXPAND_TYPE:
      return {
        ...state,
        pdpScreenCommons: {
          ...state.pdpScreenCommons,
          expandType: action.value,
        },
      };
    case EXPAND_ACCORDIAN:
      return {
        ...state,
        pdpScreenCommons: {
          ...state.pdpScreenCommons,
          currentAccordian: action.value,
        },
      };
    case MODGROUP_IDS_UPDATE:
      return {
        ...state,
        pdpScreenCommons: {
          ...state.pdpScreenCommons,
          modGroupIdsUpdated: [...state.pdpScreenCommons.modGroupIdsUpdated, action.value],
        },
      };
    case UPDATE_DEFAULT_MODGROUP:
      return {
        ...state,
        pdpScreenCommons: {
          ...state.pdpScreenCommons,
          modGroupIdsUpdated: action.value,
        },
      };
    case RESET_MODGROUP_IDS_UPDATE:
      return {
        ...state,
        pdpScreenCommons: {
          ...state.pdpScreenCommons,
          modGroupIdsUpdated: [],
        },
      };
    case CART_STATES_DATA:
      return {
        ...state,
        cartStateData: {
          showCartState: action.value.showCartModal,
        },
      };
    case CONFIRM_UPSIZE_ANALYTICS:
      return {
        ...state,
        confirmUpsizeName: action.value.confirmUpsizeName,
      };
    case SET_DISPOSITION:
      return {
        ...state,
        dispositionOld: {
          ...action.value.oldvalue,
        },
        dispositionNew: {
          ...action.value.newValue,
        },
      };
    case SET_DISPOSITION_NEW:
      return {
        ...state,
        dispositionNew: {
          ...state?.dispositionNew,
          ...action?.value?.dispositionNew,
        },
      };
    case CART_ALERT:
      return {
        ...state,
        cartAlertData: {
          showCartAlert: action.value.showCartAlert,
        },
      };
    case SET_ADDED_LOCATION:
      return {
        ...state,
        cartItems: {
          currentEditItem: action.value.editItem,
          itemLocations: [...state.cartItems.itemLocations, action.value.itemLocation],
        },
      };
    case ADD_TO_CART_CLICK: {
      return {
        ...state,
        addToCart: action.value.addToCart,
        productItemId: action.value.productItemId,
        categoryId: action.value.categoryId,
        oldProduct: action.value.oldProduct,
        customizable: action.value.customizable,
        catalogs: action.value.catalogs,
        fromPdpPage: action.value.fromPdpPage,
      };
    }
    case PLP_PDP_DETAILS: {
      return {
        ...state,
        ProductDetails: action.value,
      };
    }
    case USER_LOCALIZED: {
      return {
        ...state,
        isLocalized: action.value.isLocalized,
      };
    }
    case LANGUAGE_SELECTOR: {
      return {
        ...state,
        showLanguageSelector: action.value.showLanguageSelector,
      };
    }
    case SHOW_COPY_COUPON_ACTION: {
      return {
        ...state,
        showCouponCopied: true,
      };
    }
    case HIDE_COPY_COUPON_ACTION: {
      return {
        ...state,
        showCouponCopied: false,
      };
    }
    case SHOW_CLEAR_CART_ALERT: {
      return {
        ...state,
        showCartClearAlert: action.value.showCartClearAlert,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.value.selectedLanguage,
      };
    }
    case CLEAR_CART_CONFIRM_CANCEL_MODAL: {
      return {
        ...state,
        modalToOpenOnConfirm: action.value.modalToOpenOnConfirm,
        modalOpenFrom: action.value.modalOpenFrom,
        confirmCallBack: action.value.confirmCallBack,
      };
    }
    case CURRENT_STORE_INFO: {
      return {
        ...state,
        currentStoreInfo: action.value,
      };
    }
    case MINI_PDP_DATA: {
      return {
        ...state,
        minipdpData: {
          itemId: action.value.id,
          fromFlag: action.value.flag,
          foodLineId: action.value.foodLineId,
        },
      };
    }
    case EDIT_CLICKED: {
      return {
        ...state,
        cartEditClicked: action.value.cartEditClicked,
      };
    }
    case SET_COUPON_ERROR_MESSAGE: {
      return {
        ...state,
        couponErrorMessage: action.value.couponErrorMessage,
      };
    }
    case SET_COUPON_EXPIRY_MESSAGE: {
      return {
        ...state,
        expiryMessage: action.value.expiryMessage,
      };
    }
    case TENDER_DATA: {
      return {
        ...state,
        tenderInfo: action.value.tenderInfo,
        isPaymentAdded: action.value.isPaymentAdded,
      };
    }
    case PAYMENET_FORM_PAYLOAD: {
      return {
        ...state,
        paymentFormPayload: action.value.paymentFormPayload,
      };
    }
    case SET_QR_DEEPLINK_MODE: {
      return {
        ...state,
        deepLinkStoreMode: action.value.deepLinkStoreMode,
        deeplinkSelectedStoreId: action.value.deeplinkSelectedStoreId,
      };
    }
    case SET_PDP_DEEPLINK_MODE: {
      return {
        ...state,
        pdpDeepLinking: action.value.pdpDeepLinking,
      };
    }
    case SET_PDP_PROMO_MODE: {
      return {
        ...state,
        pdpPromoPage: action.value.pdpPromoPage,
      };
    }
    case SHOW_CART_ERROR_MODAL: {
      return {
        ...state,
        showCartErrorModal: action.value.showCartErrorModal,
      };
    }
    case AUTO_DETECTION_BUTTON_FLAG: {
      return {
        ...state,
        autoDetectionClicked: action.value.autoDetectionClicked,
      };
    }
    case LOCATION_BLOCK_BANNER: {
      return {
        ...state,
        showLocationBlockBanner: action.value.showLocationBlockBanner,
      };
    }
    case SET_FORCERELOAD: {
      return {
        ...state,
        forceReload: action.value.forceReload,
      };
    }
    case SHOW_PDP_CUSTOM_MODAL: {
      return {
        ...state,
        sopacPDPCustomization: {
          showPDPCustomization: action.value.showPDPCustomization,
          showCustomizationModId: action.value.modifierId,
          showCustomizationModGrpId: action.value.modgrpId,
          showCustomizationModGrpName: action.value.modgrpName,
          updatedPrice: action.value.updatedPrice,
        },
      };
    }
    case RESET_SHOW_PDP_CUSTOM_MODAL: {
      return {
        ...state,
        sopacPDPCustomization: {
          showPDPCustomization: false,
          showCustomizationModId: '',
          showCustomizationModGrpId: '',
          showCustomizationModGrpName: '',
          updatedPrice: 0,
        },
      };
    }
    case UPDATE_SHOW_PDP_CUSTOM_MODAL: {
      return {
        ...state,
        sopacPDPCustomization: {
          ...state.sopacPDPCustomization,
          updatedPrice: action.value.updatedPrice,
        },
      };
    }
    case PDP_ITEM_CUSTOM_TYPE: {
      return {
        ...state,
        pdpItemCustomType: action.value,
      };
    }
    case PDP_ITEM_UPSIZE_ID: {
      return {
        ...state,
        pdpItemUpsizeId: action.value.pdpItemUpsizeId,
      };
    }
    case PDP_L3_CUSTOMIZED_DATA: {
      return {
        ...state,
        pdpItemL3Data: action.value.pdpItemL3Data,
      };
    }
    case HEADER_CONSTANT.SET_HIDE_DISPOSITION_BAR: {
      return {
        ...state,
        hideDispositionBar: action.value.hideDispositionBar,
      };
    }
    case HEADER_CONSTANT.SET_HIDE_NAV_BARS: {
      return {
        ...state,
        hideAccountPageHeaders: action.value.flag,
      };
    }
    case PDP_PAYLOAD_DATA: {
      return {
        ...state,
        pdpPayloadData: action.value,
      };
    }
    case SHOWTOASTALERT: {
      return {
        ...state,
        showToastAlert: action.value,
      };
    }
    case RESET_CUSTOMIZATION: {
      return {
        ...state,
        resetCustomization: !state.resetCustomization,
      };
    }
    case SHOW_LOCATION_CONFIRM_MODAL: {
      return {
        ...state,
        showLocationConfirmModal: action.value.showLocationConfirmModal,
      };
    }
    case PDP_DATA_FOR_UPDATE_FOODLINE: {
      return {
        ...state,
        pdpDataForUpdateFoodLine: {
          itemId: action.value.id,
          flag: action.value.flag,
          foodLineId: action.value.foodLineId,
          isProductPromo: action.value?.isProductPromo,
        },
      };
    }
    case SEARCH_STORE_CONSTANTS.SET_LOCATION_BUTTON_FLAG: {
      return {
        ...state,
        setLocationClicked: action.value.setLocationClicked,
      };
    }
    case REMOVE_ITEM_FLAG: {
      return {
        ...state,
        removeItemCart: action.value.removeItemCart,
      };
    }
    case START_ORDER_CLICK: {
      return {
        ...state,
        startOrderBtnClicked: action.value.startOrderBtnClicked,
      };
    }
    case ORDER_MODE_BROWSE_MENU_CLICK: {
      return {
        ...state,
        orderModeBrowseMenuBtnClicked: action.value.orderModeBrowseMenuBtnClicked,
      };
    }
    case RESET_DELIVERY_ADDRESS_LINE: {
      const resetScheduleOrderData = {
        ...state.scheduleOrderData,
        deliveryAddressLine: '',
      };
      return { ...state, scheduleOrderData: resetScheduleOrderData };
    }
    case SET_PDP_PAGE_STATUS: {
      return { ...state, isPdpPageActive: action?.value?.isPdpPageActive };
    }
    case SET_PDP_BUTTON_DISABLED: {
      return {
        ...state,
        pdpAddtoCartConfirmButtonDisabled: action.value,
      };
    }
    case SET_ELIGIBLE_STORE: {
      return {
        ...state,
        eligibleStore: action.value.eligibleStore,
      };
    }
    case FAV_STORE_NOT_IN_RADIUS: {
      return {
        ...state,
        showNotInRadiusError: action.value.showNotInRadiusError,
        notInRadiusRestaurant: action.value.notInRadiusRestaurant,
      };
    }
    case SET_MULTI_PRODUCT_VIEW_MODAL: {
      return {
        ...state,
        isFromMultiProductViewModal: action.isFromMultiProductViewModal,
      };
    }
    case SET_FIND_A_KFC_VIEW: {
      return {
        ...state,
        findAKFC: action.value.findAKFC,
      };
    }
    case SET_ASAP_SCHEDULING: {
      return {
        ...state,
        enableASAPScheduling: action.value.enableASAPScheduling,
      };
    }
    case SET_ORDER_NOW: {
      return {
        ...state,
        showOrderNow: action.value.showOrderNow,
      };
    }
    case SET_SCHEDULE_ORDER: {
      return {
        ...state,
        showScheduleOrder: action.value.showScheduleOrder,
      };
    }
    case SET_ORDER_NOW_REORDER: {
      return {
        ...state,
        showOrderNowReorder: action.value.showOrderNowReorder,
      };
    }
    case SET_SCHEDULE_ORDER_REORDER: {
      return {
        ...state,
        showScheduleOrderReorder: action.value.showScheduleOrderReorder,
      };
    }
    case BRAZE_ACCOUNT_MSG: {
      return {
        ...state,
        accountCardLength: action.value.accountCardLength,
      };
    }
    case RECOMMENDED_SECTION_ITEM_CLICK: {
      return {
        ...state,
        isFromRecommendedSectionItemClick: action.isFromRecommendedSectionItemClick,
      };
    }
    case SCHEDULE_MODAL_OPEN: {
      return {
        ...state,
        scheduleModalOpen: action.scheduleModalOpen,
      };
    }
    case PLACE_ORDER_BUTTON: {
      return {
        ...state,
        placeOrderButtonEnabled: action.placeOrderButtonEnabled,
      };
    }
    case ORDER_SUMMARY_MODAL_CLOSE: {
      return {
        ...state,
        orderSummaryModalClose: action.orderSummaryModalClose,
      };
    }
    case UPDATE_CATERING_RETAIL_MENU_ALERT: {
      return {
        ...state,
        alertModalData: {
          ...state.alertModalData,
          flag: action.value.flag,
          pathname: action.value.pathname,
          title: action.value.title,
          contentMsg: action.value.contentMsg,
        },
      };
    }
    case SET_REDIRECTION_PATH: {
      return {
        ...state,
        redirectionOrder: action?.value?.redirectionOrder,
      };
    }
    case IS_DEEPLINK_URL: {
      return {
        ...state,
        isDeepLinkURL: action.value.isDeepLinkURL,
      };
    }
    case REORDER_MODAL_OPEN: {
      return {
        ...state,
        reorderFlag: {
          ...state.reorderFlag,
          reorderModalOpen: action.value.reorderModalOpen,
        },
      };
    }
    case FROM_REORDER_MODAL_OPEN: {
      return {
        ...state,
        reorderFlag: {
          ...state.reorderFlag,
          fromReorderFlag: action.value.fromReorderFlag,
        },
      };
    }
    case REORDER_STORE_CHANGED: {
      return {
        ...state,
        reorderFlag: {
          ...state.reorderFlag,
          storeChanged: action.value.storeChanged,
        },
      };
    }
    case MENU_NAV_ERROR: {
      return {
        ...state,
        menuNavRestricted: action.value,
      };
    }
    case UPDATE_TIMEBASED_MENU_ALERT: {
      return {
        ...state,
        timeBasedAlertModalData: action.value,
      };
    }
    case UPDATE_TIMEBASED_MENU_DATA: {
      return {
        ...state,
        timeBasedCetegory: action.value,
      };
    }
    case CHANGE_SCHEDULE_MODE: {
      return {
        ...state,
        changeScheduleModeFlag: action.value.changeScheduleModeFlag,
      };
    }
    case SCHEDULE_ORDER_CLICKED: {
      return {
        ...state,
        scheduleModeClicked: action.value.scheduleModeClicked,
      };
    }
    case SHOW_SCHEDULE_DISPOSITION: {
      return {
        ...state,
        showSheduleDisposition: action.value.showSheduleDisposition,
      };
    }
    case SET_LOCATION_INFO: {
      return {
        ...state,
        selectedDeliveryAddress: action.value.selectedDeliveryAddress,
      }
    }
    default:
      return state;
  }
};
